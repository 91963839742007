import { ReactElement } from "react";

function PageNotFound(): ReactElement {
    return (
        <div style={{ width: '100%', height: '100%' }}>
        <img style={{ position: 'absolute', width: '100%', height: '100%' }} src = "placeholder.jpeg"/>
        <div style={{ position: 'absolute', color: 'white', fontSize: 23 }}>{"I'm Commander Shepherd and, this, is my favorite page on the Citadel."}</div></div>
    )
}

export default PageNotFound;
