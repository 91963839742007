import { FormEvent } from "react";
import { ICategory, IDoDICData, IChapter } from "./interfaces";
import stracDataJson from "./stracData.json";
import React from "react";

export enum COLOR {
    ARMY_GOLD = `#FDBB19`,
    ARMY_BLACK = `#0F0F0F`,
    ARMY_GRAY = `#B2B4B3`,
    PURPLE = `#9F2B68`,
}

export const MAX_PAGES = 100

export const APP_HEADER_HEIGHT = 75
export const APP_FOOTER_HEIGHT = 16

export const STR_APP_FOOTER_BUG_SUBMISSIONS_MESSAGE = `Contact Us`;
export const STR_PROJECT_MANAGER_INFO = `Project Manager : SSG Matthew Harp`;
export const STR_PROJECT_MANAGER_EMAIL = `matthew.r.harp9.mil@army.mil`;

export const MODAL_STYLE_FIT_CONTENT = {
    content: {
        width: '80%',
        height: 'fit-content',
        maxHeight: '80%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background: COLOR.ARMY_GOLD,
        border: '2px solid black',
        borderRadius: '25px',
        transform: 'translate(-50%, -50%)',
    }
}

export function StyledAppButton(props: { text?: string, style?: React.CSSProperties, onClick: (value: InputEvent | FormEvent) => void, disabled?: boolean}): React.ReactElement {
    return (
        <button style={{ display: 'flex', borderRadius: '5px', alignItems: 'center', minHeight: '80px', 
            height: '80px', width: '-webkit-fill-available', ...props.style }} onClick={props.onClick} disabled={props.disabled}>
            <span style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.text}</span>
        </button>
    )
}

function createDodicList(): IDoDICData[] {
    const allDodics: IDoDICData[] = [];
    for (let i = 0; i < stracDataJson.ARMY_DODICS.length; i++) allDodics.push(stracDataJson.ARMY_DODICS[i])
    return allDodics;
}
export const ARMY_DODICS = createDodicList();


function createTestCategory(): ICategory {
    const testCategory: ICategory = {name: "Development Tools", chapters:["DEBUG"], events: [{name: '1 Of Everything', strategies: [], isSelected: false}], tags: ["DEBUG"]};
    for (let i = 0; i < ARMY_DODICS.length; i++) testCategory.events[0].strategies.push({charRep: ARMY_DODICS[i].charRep, count: i+1})
    return testCategory;
}

function createCategoriesList(): ICategory[] {
    const allCategories: ICategory[] = [];
    for (let i = 0; i < stracDataJson.CATEGORIES.length; i++) allCategories.push(stracDataJson.CATEGORIES[i])
    return allCategories;
}

function createChapterList(): IChapter[] {
    const allChapters: IChapter[] = [];
    for (let i = 0; i < stracDataJson.CHAPTERS.length; i++)
        if (stracDataJson.CHAPTERS[i].color) allChapters.push(stracDataJson.CHAPTERS[i])
            else allChapters.push({name: stracDataJson.CHAPTERS[i].name, tags: stracDataJson.CHAPTERS[i].tags, image: stracDataJson.CHAPTERS[i].image, color: 'white'})
    return allChapters;
}

function createChapterNameList(): string[] {
    const allChapterNames: string[] = [];
    for (let i = 0; i < stracDataJson.CHAPTERS.length; i++) allChapterNames.push(stracDataJson.CHAPTERS[i].name)
    return allChapterNames;
}

export const CATEGORY_TEST = createTestCategory();
export const ALL_CATEGORIES = createCategoriesList();
export const ALL_CHAPTERS = createChapterList();
export const ALL_CHAPTER_NAMES = createChapterNameList();

function createWeaponTagList(): string[] {
    const allWeaponTags: string[] = [];
    let breakIndex = NaN;
    for (let i = 0; i < ALL_CATEGORIES.length; i++){
        if (ALL_CATEGORIES[i].tags[0] === "Weapon")
        for (let y = 0; y < ALL_CATEGORIES[i].tags.length; y++)
            if (!breakIndex && ALL_CATEGORIES[i].tags[y] === "Break") breakIndex = y;
        if (breakIndex) for (let x = 1; x <= breakIndex; x++){
            if(ALL_CATEGORIES[i].tags[x] !== "Break" && !allWeaponTags.includes(ALL_CATEGORIES[i].tags[x]))
                allWeaponTags.push(ALL_CATEGORIES[i].tags[x]);
            if (x === breakIndex){ 
                breakIndex = NaN;
                break;
            }
        }
    }
    return(allWeaponTags);
}

export const ALL_WEAPON_TAGS = createWeaponTagList();

export const CATEGORY_DEFAULT: ICategory = {
    name: 'SELECT CATEGORY',
    chapters: ALL_CHAPTER_NAMES,
    events: [],
    tags: []
}
