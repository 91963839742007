import { ReactElement, useState } from "react";
import { ALL_CATEGORIES, ALL_CHAPTERS, ARMY_DODICS, COLOR, MODAL_STYLE_FIT_CONTENT, StyledAppButton } from "../constants";
import { ICategory, IChapter, IDoDICData } from "../interfaces";
import AppFooter from "./appfooter";
import Select from 'react-select/creatable'
import React from "react";
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import Modal from 'react-modal';

enum EModalState {
    CLOSED,
    SELECTING_DODIC
}

function Editor(): ReactElement {
    interface Option {
        readonly label: string;
        readonly value: string;
      }

      const [modalState, setModalState] = React.useState<EModalState>(EModalState.CLOSED);
      const [chapters, ] = React.useState<IChapter[]>(ALL_CHAPTERS);
      const [categories, ] = React.useState<ICategory[]>(ALL_CATEGORIES);
      const [dodics, ] = React.useState<IDoDICData[]>(ARMY_DODICS);

      function renderModal(): React.ReactElement {
        switch (modalState) {
            case EModalState.SELECTING_DODIC:
                return <div>{renderAddDodicList()}</div>
            default:
                return <div>Oh Noers :(</div>
        }
    }
    
    function createChapterList() {
        const chapterOptions = [];
        for (let i = 0; i < chapters.length; i++) chapterOptions.push({value: chapters[i].name, label: chapters[i].name});
        return chapterOptions;
    }

    function createCategoriesList() {
        const categoryOptions = [];
        for (let i = 0; i < categories.length; i++){
            for(let x = 0; x < categories[i].chapters.length; x++)
                if(categories[i].chapters[x] === chapValue?.value)
                    categoryOptions.push({value: categories[i].name, label: categories[i].name});
        }
        return categoryOptions;
    }

    function createDodicList() {
        const dodicOptions = [];
        for (let i = 0; i < dodics.length; i++) dodicOptions.push({value: (dodics[i].charRep+', '+dodics[i].nsn+', '+dodics[i].ammoName), label: dodics[i].nsn});
        return dodicOptions;
    }

    const [chapOptions, setChapOptions] = useState(createChapterList());
    const [chapValue, setChapValue] = useState<Option | null>();
    const [catOptions, setCatOptions] = useState(createCategoriesList());
    const [catValue, setCatValue] = useState<Option | null>();
    const [eveOptions, setEveOptions] = useState(createEventList());
    const [eveValue, setEveValue] = useState<Option | null>();
    const [editState, setEditState] = React.useState<boolean>(false);

    const dodicOptions = createDodicList();

    React.useEffect(() => {
        setCatOptions(createCategoriesList());
    }, [chapValue])
    React.useEffect(() => {
        setEveOptions(createEventList());
    }, [catValue])
    React.useEffect(() => {
        const doNothing = () => { return }
        doNothing()
    }, [editState])

    function createEventList() {
        let category: ICategory = 
        {
            "name": "Dummy Category",
            "chapters":["DEBUG"],
            "events": [
                { "name": "You shouldn't see this", "strategies": [], "isSelected": false },
            ],
            "tags": ["DEBUG"]
        };
        for(let i = 0; i < categories.length; i++)
            for(let x = 0; x < categories[i].chapters.length; x++)
                if (catValue?.label === categories[i].name && categories[i].chapters[x] === chapValue?.label){ 
                    category = categories[i];
        }
        const eventOptions = [];
        for (let i = 0; i < category.events.length; i++) eventOptions.push({value: category.events[i].name, label: category.events[i].name});
        return eventOptions;
    }

    const createOption = (label: string) => ({
        label,
        value: label,
      });

    const handleCreate = (inputValue: string, level: string) => {
        const newOption = createOption(inputValue);
        if(level === 'chapter'){
            setChapOptions((prev) => [...prev, newOption]);
            setChapValue(newOption);
            chapters.push({"name": newOption.label, "tags": [], "image": ''});
        }
        if(level === 'category'){
            setCatOptions((prev) => [...prev, newOption]);
            setCatValue(newOption);
            categories.push({ "name": newOption.label, "chapters": [`${chapValue?.label}`], "events": [], "tags": [] })
        }
        if(level === 'event'){
            setEveOptions((prev) => [...prev, newOption]);
            setEveValue(newOption);
            const index = categories.findIndex(obj =>{
                return (obj.name === catValue?.label && obj.chapters.includes(`${chapValue?.label}`));
            })
            categories[index].events.push({ "name": newOption.label, "strategies": [], "isSelected": false })
        }
      };

      const renderDodics = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];
        if(chapValue && catValue && eveValue){
        const catIndex = categories.findIndex(obj =>{
            return (obj.name === catValue?.label && obj.chapters.includes(`${chapValue?.label}`));
        });
        const eveIndex = categories[catIndex].events.findIndex(obj =>{
            return (obj.name === eveValue?.label);
        });
        if(eveIndex != -1){
        for (let i = 0; i < categories[catIndex].events[eveIndex].strategies.length; i++) {
            for (let x = 0; x < ARMY_DODICS.length; x++){
                if(categories[catIndex].events[eveIndex].strategies[i].charRep == ARMY_DODICS[x].charRep){
                elements.push(
                <div id={`calculation-${i}`}>
                    <TextField
                        id={`calculationText-${i}`}
                        title=''
                        helperText={`${ARMY_DODICS[x].ammoName}`}
                        placeholder={`${categories[catIndex].events[eveIndex].strategies[i].count}`}
                        type="number"
                        sx={{
                            borderStyle: 'inset',
                            borderWidth: '2px',
                            minWidth: '100px',
                            width: '25vw',
                            borderColor: `${COLOR.ARMY_GOLD}`,
                            backgroundColor: 'white',
                            '&:hover': {
                            backgroundColor: 'white',
                            opacity: [0.9, 0.8, 0.7]
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Tooltip title="Delete from event">
                        <IconButton aria-label="delete" size="large" sx={{ color: "grey", "&:hover": { color: "red" } }} id={`${i}`} onClick={(e) => { 
                            categories[catIndex].events[eveIndex].strategies.splice(parseInt(e.currentTarget.id), 1);
                            setEditState(!editState);
                        }}>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                </div>)}}
        }}
    }
        return elements;
    }

    function renderAddDodicList(): React.ReactElement[] {
        const elements: React.ReactElement[] = [];
        const catIndex = categories.findIndex(obj =>{
            return (obj.name === catValue?.label && obj.chapters.includes(`${chapValue?.label}`));
        });
        const eveIndex = categories[catIndex].events.findIndex(obj =>{
            return (obj.name === eveValue?.label);
        });
        for (let i = 0; i < dodicOptions.length; i++) {
            elements.push(<StyledAppButton key={`dodicsOptions-${i}`} 
                style={{ minHeight: '80px', height: '80px', width: '-webkit-fill-available' }}
                onClick={() => {
                    categories[catIndex].events[eveIndex].strategies.push({charRep: dodicOptions[i].value.split(',', 1)[0], count: 0})
                    setModalState(EModalState.CLOSED);
                }}
                text={dodicOptions[i].value}
                />)
        }

        return elements;
    }

    return (
        <div style={{height: '100vh', width: '100vw', backgroundColor: "black"}}>
            <div style= {{ textAlign: 'center', display: 'flex', flexDirection: 'row', color: 'white', width: '100%', fontSize: '24px', top: '5px',
                borderBottom: `3px solid ${COLOR.ARMY_GOLD}`, justifyItems: 'center' }}>AmmoOp Editor
            </div>
            <div style={{width: '48%', position: 'absolute'}}>
                <Select
                    id={'chapterSelection'}
                    placeholder="Select a Chapter..."
                    isClearable
                    options={chapOptions}
                    value={chapValue}
                    onChange={(newValue) => {setChapValue(newValue), setCatValue(null), setEveValue(null)}}
                    onCreateOption={(inputValue) => {handleCreate(inputValue, 'chapter'), setCatValue(null), setEveValue(null)}} />
            </div>
            {    chapValue &&
            <div style={{width: '48%', translate: '100%', position: 'absolute', right: '48%'}}>
                <Select id={'categorySelection'}
                    placeholder="Select a Category..."
                    isClearable
                    options={catOptions}
                    value={catValue}
                    onChange={(newValue) => {setCatValue(newValue), setEveValue(null)}}
                    onCreateOption={(inputValue) => {handleCreate(inputValue, 'category'), setEveValue(null)}} />
            </div>
            }
            {   catValue &&
            <div style={{width: '100%', position: 'absolute', top: '10%'}}>
                <Select id={'eventSelection'}
                    placeholder="Select an Event..."
                    isClearable
                    options={eveOptions}
                    value={eveValue}
                    onChange={(newValue) => setEveValue(newValue)}
                    onCreateOption={(inputValue) => handleCreate(inputValue, 'event')} />
            </div>
            }
            {   eveValue &&
            <div id={'dodicsBox'} style={{ textAlign: 'center', right: '12.5%', top: '15%', fontSize: '24px',
                overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: `${COLOR.ARMY_GOLD}, black`, width: '75%', 
                height: '50vh', border: '2px solid', position: 'absolute'}}>
                {renderDodics()}
                <Tooltip title="Add new DODIC to event">
                <IconButton aria-label="add" size="large" sx={{ color: "grey", "&:hover": { color: "blue" } }} onClick={() => { 
                    setModalState(EModalState.SELECTING_DODIC)
                }}>
                    <AddIcon fontSize="inherit"/>
                </IconButton>
            </Tooltip>
            </div>
            }
            <Modal
                isOpen={modalState !== EModalState.CLOSED}
                ariaHideApp={false}
                onRequestClose={() => {setModalState(EModalState.CLOSED)}}
                style={MODAL_STYLE_FIT_CONTENT}
                contentLabel="AmmoOp Modal"
            >
                {renderModal()}
            </Modal>
            <AppFooter />
        </div>
    )
}

export default Editor;